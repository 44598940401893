
















































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import DocumentIdentifier from '@/models/document/DocumentIdentifier'
import Document from '@/models/document/Document'
import { vxm } from '@/store'
import pdf from 'pdfvuer'
// import printJS from 'print-js-updated'
import { getUserSetting } from '@/utils/userUtils'
import Recipients from '@/models/document/Recipients'

@Component({
  components: {
    pdf,
  },
})
export default class DocumentDialog extends Vue {
  @Prop({ type: DocumentIdentifier, required: false })
  private identifier: DocumentIdentifier

  @Prop({ type: Boolean, required: false })
  private value: boolean

  private document: Document = null
  private visible = false
  private loading = false
  private printers: Array<Record<string, unknown>> = []
  private hasCloudPrint = true // todo: from features / settings
  private printer = null
  private copies = 1
  private email = ''
  private pdfScale = 'page-width'
  private pdfPage = 1
  private showPdf = false
  private pdfData = ''
  private pdfKey = ''
  private pdfPages = 0
  private fullscreen = false
  private recipients: Recipients = null

  // ================================================================
  // Manage dialog visibility
  // ================================================================

  // On created, start with the visibility given by the v-model prop
  public created(): void {
    if (this.value) {
      this.visible = true
    }
    this.loadPrinters(() => {
      this.loadDocument()
    })
  }

  // If the v-model prop is changed from outside, update our visibility
  @Watch('value')
  private onValueChange(value) {
    this.visible = value
  }

  // If we programmatically close dialog in here, emit to the v-model outside
  private close() {
    this.$emit('input', false)
    this.fullscreen = false
  }

  // If user clicks outside the dialog to close it, also emit to the v-model outside
  private onClickOutside() {
    this.close()
  }

  // ================================================================
  // Init
  // ================================================================

  @Watch('identifier')
  private onIdentifierChange() {
    this.loadDocument()
  }

  private getPrintIframe() {
    // Stupid Edge at DinBil cannot handle printing base64 PDF via PrintJS,
    // https://git.eonbit.com/eontyre/application/-/issues/2075
    const id = 'v4-doc-print-iframe'
    return document['frames'] ? document['frames'][id] : document.getElementById(id)
  }

  private loadDocument() {
    this.pdfData = ''
    this.pdfKey = ''
    this.showPdf = false
    if (!this.identifier) {
      this.document = new Document()
      this.document.title = 'No document'
      return
    }
    this.$axios
      .post('/v4/site/documents/document/get', { type: this.identifier.type, params: this.identifier.params })
      .then((response) => {
        const responseData = response.data.data

        this.document = new Document()
        this.document.title = responseData.title
        this.document.filename = responseData.filename
        this.document.data = responseData.data
        this.showPdf = true
        this.pdfData = 'data:application/pdf;base64,' + this.document.data
        this.pdfKey = JSON.stringify(this.identifier)
        this.pdfPage = 1
        if (Object.keys(responseData.recipients).length > 0) {
          this.recipients = new Recipients(responseData.recipients)
          if (this.recipients?.default) {
            this.setEmailAddress(this.recipients.default)
          }
        } else {
          this.recipients = null
          this.email = null
        }
        this.loadSelectedPrinter()
        // Stupid Edge at DinBil cannot handle printing base64 PDF via PrintJS,
        // https://git.eonbit.com/eontyre/application/-/issues/2075
        this.getPrintIframe().src = responseData.publicUrl
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Could not fetch document')
      })
  }

  public setPdfPages(pages: number): void {
    this.pdfPages = pages
  }

  private loadPrinters(callback: () => void) {
    this.$axios
      .get('/v4/site/printing/printers')
      .then((response) => {
        this.printers = response.data.data
        if (callback) {
          callback()
        }
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Could not load printers')
        this.printers = []
      })
  }

  private loadSelectedPrinter() {
    getUserSetting('eontyre-gcp-printers-' + this.identifier.type, (value, error) => {
      if (error) {
        vxm.alert.error({
          content: 'Error loading selected printer',
          title: 'Error loading selected printer',
        })
      } else {
        this.printer = parseInt(value)
      }
    })
  }

  // ================================================================
  // Accessors
  // ================================================================

  private get title() {
    return this.$t('Document') // todo: maybe show actual name of document, like "Work order"
  }

  // ================================================================
  // Printing
  // ================================================================

  private clickCloudPrint() {
    if (!this.printer) {
      vxm.alert.error('Must select printer first')
      return
    }
    const params = {
      document: this.identifier,
      printer: this.printer,
      copies: 1,
    }
    this.$axios
      .post('/v4/site/printing/print/document', params)
      .then((_response) => {
        vxm.alert.success('Print job queued')
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Could not print document')
      })
  }

  private clickLocalPrint() {
    // printJS({ printable: this.pdfData, type: 'pdf', base64: true })
    // printJS({ printable: this.document.data, type: 'pdf', base64: true, onError: function(e) { console.error('error printing',e); }})

    // Stupid Edge at DinBil cannot handle printing base64 PDF via PrintJS,
    // https://git.eonbit.com/eontyre/application/-/issues/2075

    const iframe = this.getPrintIframe()
    const ifWin = iframe.contentWindow || iframe
    iframe.focus()
    ifWin.print()

  }

  private clickSend() {
    if (!this.email) {
      vxm.alert.error('Must enter e-mail address')
      return
    }
    const params = {
      document: this.identifier,
      email: this.email,
    }
    this.$axios
      .post('/v4/site/notification/send/document', params)
      .then((_response) => {
        vxm.alert.success(this.$t('E-mail successfully sent'))
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Could not send email')
      })
  }

  private clickFullscreen() {
    this.fullscreen = !this.fullscreen
  }

  private clickDownload() {
    const a = document.createElement('a')
    a.href = this.pdfData
    a.download = this.document.filename
    a.click()
  }

  private setEmailAddress(type: string) {
    if (type === 'customer') {
      this.email = this.recipients.customer
    } else if (type === 'driver') {
      this.email = this.recipients.driver
    }
  }
}
