















































































































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import ListView from '@/components/list/ListView.vue'
import PageTab from '@/components/ui/PageTab.vue'
import TemplateComponent from '@/components/TemplateComponent.vue'
import { appendSitePrefix } from '@/utils/routeUtils'
import { vxm } from '@/store'
import DocumentIdentifier from '@/models/document/DocumentIdentifier'
import DocumentDialog from '@/components/document/DocumentDialog.vue'

@Component({
  components: { DocumentDialog, TemplateComponent, PageTab, ListView },
})
export default class List extends Vue {
  private appendSitePrefix = appendSitePrefix
  private listViewKey = 0
  private loading = false

  private rowActions = []
  private headers = []
  private filteringLocations = []
  private locations = []
  private tyreCounters = []

  private detailedData = false
  private reportsDialog = false
  private blankCertificateDialog = false

  private data = {}

  private document = null
  private location = null
  private locationType = 'shop'

  async created() {
    this.loading = true
    await this.loadAdditionalData()
    this.setInitialHeaders()
    this.setRowActions()
  }

  private async loadAdditionalData() {
    try {
      this.loading = true

      const tyreCounterResponse = await this.$axios.get('/v4/site/tyre-hotels/statistics')
      this.tyreCounters = tyreCounterResponse.data

      const locationsResponse = await this.$axios.get('/v4/site/locations')
      this.locations = locationsResponse.data.data
      this.filteringLocations = [...this.locations]

      const allOption = { id: 0, name: 'All' }
      this.filteringLocations.unshift(allOption)

      this.loading = false
    } catch (err) {
      await vxm.alert.onAxiosError(err, 'Error loading tyre counter or location')
      this.loading = false
    }
  }

  private setRowActions() {
    this.rowActions = [
      {
        id: 'view',
        route: (item) => {
          return this.appendSitePrefix('/tyre_hotel/' + item.id + '/storage/item/null')
        },
      },
    ]
  }

  private navigateTyreToReport(preset, season) {
    const url = appendSitePrefix(`/tyre_hotel/U/storage/report2/null/root/_rep_preset=${preset}%7Cseason=${season}`)
    this.$router.push(url)
  }

  private generateReport(reportName) {
    const url = appendSitePrefix(
      `/tyre_hotel/U/generic_report/main/null/root/desc=_id%7Creport=TyreHotels${reportName}`,
    )
    this.$router.push(url)
  }

  private openReport(reportUrl) {
    const url = appendSitePrefix(`/tyre_hotel/${reportUrl}`)
    this.$router.push(url)
  }

  private blankCertificate() {
    this.document = new DocumentIdentifier()
    this.document.type = 'TyreHotelCertificateBlank'
    this.document.params = {}
    this.blankCertificateDialog = true
  }

  get url() {
    return '/v4/site/tyre-hotels'
  }

  private newTyreHotelDialog(): void {
    this.$router.push(appendSitePrefix('/tyre_hotel/N0/storage/new'))
  }

  private detailed(detailed) {
    this.detailedData = detailed
    detailed ? this.setDetailedHeaders() : this.setInitialHeaders()
    if (!detailed) {
      this.location = ''
      this.locationType = 'shop'
    }
  }

  private formatStatus(status) {
    return status === 1 ? this.$t('c:common:Active') : this.$t('c:tyre-hotel-list:Terminated')
  }

  private formatBrandName(brandName, model) {
    return `${brandName} ${model}`
  }

  private formatTrackDepth(item) {
    const mmValues = item.tyreHotelLines
      .map((tyreLine) => tyreLine.mm)
      .filter((mm) => mm !== null && mm !== undefined)
    return mmValues.length > 0 ? mmValues.join(', ') : ''
  }

  private formatSeasonInStock(season) {
    return season === 1 ? this.$t('c:common:Summer') : this.$t('c:common:Winter')
  }

  private formatIsStudded(isStudded) {
    return isStudded === 1 ? this.$t('c:common:Yes') : this.$t('c:common:No')
  }

  private setInitialHeaders() {
    this.headers = [
      {
        text: 'c:tyre-hotel-list:Tyre Hotel id',
        value: 'id',
        filter: { disable: false },
      },
      {
        text: 'c:tyre-hotel-list:License plate',
        value: 'car.licenseplate',
        filter: { disable: false },
      },
      {
        text: 'c:tyre-hotel-list:Car model',
        value: 'car.brand.name',
        filter: { disable: false },
      },
      {
        text: 'c:common:Product',
        value: 'productItem.eontyreDescription',
        filter: { disable: false },
      },
      {
        text: 'c:common:Customer',
        value: 'car.customer.name',
        filter: { disable: false },
      },
      {
        text: 'c:common:Driver',
        value: 'car.driver.name',
        filter: { disable: false },
      },
      {
        text: 'c:tyre-hotel-list:Shop Location',
        value: 'locations',
        filter: {
          items: this.locations,
          itemText: 'name',
          itemValue: 'id',
        },
        sortable: false,
      },
      {
        text: 'c:tyre-hotel-list:Position',
        value: 'positions',
        filter: { disable: true },
      },
      {
        text: 'c:tyre-hotel-list:Season in stock',
        value: 'seasonInStock',
        filter: {
          items: [
            { text: this.$t('c:common:Summer'), value: 1 },
            { text: this.$t('c:common:Winter'), value: 2 },
          ],
        },
      },
      {
        text: 'c:common:Actions',
        value: 'actions',
      },
    ]
  }

  private setDetailedHeaders() {
    this.setInitialHeaders()
    // this removes the location from headers
    const locationColumnIndex = this.headers.findIndex((header) => header.value === 'locations')
    if (locationColumnIndex !== -1) {
      this.headers.splice(locationColumnIndex, 1)
    }
    // this auto selects the All option with id 0 in v-autocomplete of locations
    this.location = 0
    this.headers.splice(
      8,
      0,
      {
        text: 'c:common:Comment',
        value: 'comment',
        filter: { disable: false },
      },
      {
        text: 'c:common:Status',
        value: 'status',
        filter: {
          items: [
            { text: this.$t('c:tyre-hotel-list:Terminated'), value: 0 },
            { text: this.$t('c:common:Active'), value: 1 },
            { text: this.$t('c:common:Inactive'), value: 2 },
          ],
        },
      },
      {
        text: 'c:tyre-hotel-list:Track Depths',
        value: 'tyreHotelLines.mm',
        filter: { disable: true },
        sortable: false,
      },
      {
        text: 'c:tyre-hotel-list:Is studded',
        value: 'isStudded',
        filter: { disable: true },
        sortable: false,
      },
    )
  }

  private get query() {
    return {
      location: this.location,
      locationType: this.locationType,
      detailed: this.detailedData,
    }
  }
}
