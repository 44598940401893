import { axios } from '@/plugins/vueaxios'

export function setUserSetting(key: string, value: unknown, callback: () => void): void {
  axios
    .post('/v4/site/user/settings/' + key, { value: value })
    .then((_response) => {
      if (callback) {
        callback()
      }
    })
    .catch((err) => {
      console.error('user.setSetting: error setting ' + key + ' = ' + value, err)
      if (callback) {
        callback()
      }
    })
}

export function getUserSetting(key: string, callback: (value: string, error: string) => void): void {
  axios
    .get('/v4/site/user/settings/' + key)
    .then((response) => {
      if (callback) {
        callback(response.data.data.value, null)
      }
    })
    .catch((err) => {
      console.error('user.getSetting: error getting setting ' + key, err)
      if (callback) {
        callback(null, '' + err)
      }
    })
}
