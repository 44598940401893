export default class Recipients {
  public customer: string
  public driver: string
  public default: string

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }

    this.customer = data.customer ? (data.customer as string) : null
    this.driver = data.driver ? (data.driver as string) : null
    this.default = data.default ? (data.default as string) : null
  }

  public clone(): Recipients {
    const result = new Recipients()
    Object.assign(result, this)
    return result
  }
}
